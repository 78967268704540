import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { yupLocale } from './yup-locale'
import LanguageDetector from "i18next-browser-languagedetector"

import translations from './translations.json'

const resources = {
    translations
}

export let availableLanguages = Object.keys(resources.translations)
//availableLanguages.push('dog')

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources.translations,
        defaultNS: 'app',
        ns: ['app'],
        fallbackLng: 'en'
    },
    yupLocale
)