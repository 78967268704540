// eslint-disable-next-line no-shadow
export enum PageLinkType {
	INTERNAL,
	EXTERNAL,
}

export interface IPageLink {
	text: string;
	link: string;
	type: PageLinkType;
	iconUrl?: string;
	translationKey: string;
}
