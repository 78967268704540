import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTheme, styled } from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Unstable_Grid2'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'

import { IPageLink, PageLinkType } from '../constants'
import { useTranslation } from 'react-i18next'

interface Props {
    accountLinks: IPageLink[],
    navigationLinks: IPageLink[],
    userDisplayName?: string,
    userDisplayImage: string,
    currentRoute: string,
    numberOfUnreadMessages: number
}

export default function Render(props: Props) {
    const { t } = useTranslation()
    const { colours } = useTheme()

    const [elementUserMenu, setElementUserMenu] = useState(null)

    const handleOpenUserMenu = (event: any) => {
        setElementUserMenu(event.currentTarget)
    }
    
    const handleCloseUserMenu = () => {
        setElementUserMenu(null)
    }

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: colours.red,
            color: colours.red,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""'
            }
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0
            }
        }
    }))

    return (
        <Grid container sx={{ width:'100%' }}>
            <Grid xs={1}>
                <Typography component='a' href='/dashboard'>
                    <Box
                        component='img'
                        src='/images/logo.svg' 
                        sx={{ width: '154px', height: '48px', display: 'block' }}
                    />
                </Typography>
            </Grid>
            <Grid xs sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap:'30px' }}>
                    {props.navigationLinks.map((page) => { 
                        const isCurrentPage = props.currentRoute == page.link

                        return (
                            <Typography
                                component={Link}
                                to={page.link}
                                key={page.text}
                                data-tour-id={page.dataTourId ?? ''}
                                sx={{ 
                                    display: 'block',
                                    fontSize: 16,
                                    textDecoration: 'none',
                                    fontWeight: 500,
                                    lineHeight: '26px',
                                    color: colours.greyDark,
                                    p: 1,
                                    borderTop: '2px solid transparent',
                                    borderBottom: '2px solid transparent',
                                    borderBottomColor: isCurrentPage ? colours.greenDark : 'transparent',
                                    '&:hover': {
                                        borderBottomColor: colours.greenDark,
                                    }
                                }}
                            >
                                {page.text}
                            </Typography>
                    )})}
                </Box>
            </Grid>
            <Grid lg='auto' sx={{ display: 'flex', alignItems: 'right' }}>
                <Box>
                    <Tooltip
                        title={t('common.user_icon_hover_info.open_account_settings')}
                        onClick={handleOpenUserMenu}
                    >
                        <IconButton 
                            onClick={handleOpenUserMenu} 
                            sx={{ p: 0 }} 
                            style={{ backgroundColor: 'transparent' }}
                            data-tour-id='tour-header-account'
                        >
                            <StyledBadge
                                overlap='circular'
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                variant='dot'
                                sx={{
                                    '& .MuiBadge-badge': {
                                        display: props.numberOfUnreadMessages === 0 ? 'none' : 'block'
                                    }
                                }}
                            >
                                <Avatar alt={props.userDisplayName} sx={{ width: 46, height: 46 }} 
                                src={props.userDisplayImage} />
                            </StyledBadge>

                            <Typography noWrap component={'span'} sx={{ ml: 2, display: 'inline', color: colours.greyDark, size: 16, fontWeight: 500, maxWidth: '250px' }}>
                                {props.userDisplayName}
                            </Typography>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ 
                            mt: '60px', 
                            
                            '& .MuiList-root': { display:'flex', flexDirection: 'column', },
                            '& .MuiPaper-root': { borderRadius: '8px', minWidth: '250px', px: 0, py: 0 }, 
                            '& .MuiMenuItem-root': {
                                my: 0,
                                py: 0,
                                '&:hover': {
                                    backgroundColor: colours.greenLight                                              
                                },
                            }
                        }}
                        id='menu-appbar'
                        anchorEl={elementUserMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        elevation={1}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(elementUserMenu)}
                        onClose={handleCloseUserMenu}
                    >
                        {props.accountLinks.map((setting) => (
                            <MenuItem key={setting.text} onClick={handleCloseUserMenu} sx={{ 
                                borderBottom: '2px solid transparent', 
                                '&:hover': {
                                    borderBottomColor: colours.greenDark,
                                }
                            }}>
                                {
                                    setting.type === PageLinkType.INTERNAL && (
                                        <Typography 
                                            component={Link}
                                            to={setting.link}
                                            sx={{ 
                                                textDecoration: 'none', 
                                                color: colours.greenDark, 
                                                fontWeight: 400, 
                                                fontSize: 16, 
                                                py: 1, 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                gap: 1,
                                                width: '100%'
                                            }}
                                            textAlign='center'>
                                                <Avatar sx={{ width: 20, height: 20 }} src={setting.iconUrl} />
                                                {setting.text}
                                                { 
                                                    setting.alias === 'messages'
                                                    && props.numberOfUnreadMessages > 0 
                                                    && (
                                                        <>
                                                            <Avatar
                                                                sx={{
                                                                    backgroundColor: colours.greenDark,
                                                                    color: colours.white,
                                                                    fontSize: '12px',
                                                                    p: '4px 6px',
                                                                    width: 'auto',
                                                                    height: 'auto',
                                                                    lineHeight: '12px',
                                                                    textAlign: 'center'
                                                                }}>
                                                                {props.numberOfUnreadMessages}
                                                            </Avatar>
                                                            <StyledBadge
                                                                sx={{
                                                                    ml: 'auto'
                                                                }}
                                                                overlap='circular'
                                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                                variant='dot'
                                                            />
                                                        </>
                                                    )
                                                }   
                                        </Typography>
                                    )
                                }
                                {
                                    setting.type === PageLinkType.EXTERNAL && (
                                        <Typography 
                                            component='a'
                                            onClick={setting.onClick}
                                            sx={{ textDecoration: 'none', color: colours.greyDark, fontWeight: 400, fontSize: 16, py: 1, display: 'flex', alignItems: 'center', gap: 1 }}
                                            textAlign='center'>
                                                <Avatar sx={{ width: 20, height: 20 }} src={setting.iconUrl} />
                                                <span>{setting.text}</span>
                                        </Typography>
                                    )
                                }
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Grid>
        </Grid>
    )
}