import React, {useEffect, useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, CircularProgress, CssBaseline } from '@mui/material'
import AppContent from './app-content'
import AppContentPublic from './app-content-public'
import {matchRoutes, useLocation, useNavigate} from 'react-router-dom'

export default function Render() {
    
    const { 
        isAuthenticated: auth0IsAuthenticated, 
        isLoading: auth0IsLoading,
        error: auth0Error
    } = useAuth0()
    const location = useLocation()
    const navigate = useNavigate()

    const publicRoutes = [
        { path: '/:locale?'},
        { path: '/:locale?/about'},
        { path: '/:locale?/buddies'},
        { path: '/:locale?/doggies'},
        { path: '/:locale?/meetups'},
        { path: '/:locale?/services'},
        { path: '/:locale?/roadmap'},
        { path: '/:locale?/perfect-introduction'},
        { path: '/:locale?/cantonal-information'},
        { path: '/:locale?/community-guidelines'},
        { path: '/:locale?/imprint'},
        { path: '/:locale?/privacy-policy'},
        { path: '/:locale?/terms-conditions'},
        { path: '/:locale?/how-you-can-help'}
    ]
    
    useEffect(() => {

        let currentPath = ''
        const matchedRoutes = matchRoutes(publicRoutes, location.pathname);

        if (matchedRoutes && matchedRoutes.length > 0) {
            const route = matchedRoutes[0].route;
            if (route && route.path) {
                currentPath = route.path;
            }
        }

        if (currentPath !== '')
            return
    }, [
        auth0IsAuthenticated, 
        auth0IsLoading
    ])

    useEffect(() => {
        if (auth0Error?.name === 'Error'
            && auth0Error.message === 'account-verification-required')
        {
            navigate(`/account-verification`)
        }
    }, [auth0Error]);

    if (auth0IsLoading)
        return (
            <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', width:'100%', alignItems: 'center' }}>
                <CssBaseline />
                <CircularProgress />
            </Box>
        )

    return (
        <>
        {
            auth0IsAuthenticated ? (
                <AppContent />
            ) : (
                <AppContentPublic />
            )
        }
        </>
    )
}