import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollDirection from './components/use-scroll-direction'
import { IPageLink, PageLinkType } from './constants'
import HeaderMobile from "./components/header-mobile";
import HeaderDesktop from "./components/header-desktop";
import { useTranslation } from "react-i18next";

const navigationLinks: IPageLink[] = [
	{ text: 'Doggies', link: 'doggies', type: PageLinkType.INTERNAL, translationKey: 'nav.doggies' } as IPageLink,
    { text: 'Buddies', link: 'buddies', type: PageLinkType.INTERNAL, translationKey: 'nav.buddies' } as IPageLink,
	{ text: 'Meetups', link: 'meetups', type: PageLinkType.INTERNAL, translationKey: 'nav.meetups' } as IPageLink,
	{ text: 'Services', link: 'services', type: PageLinkType.INTERNAL, translationKey: 'nav.services' } as IPageLink,
	{ text: 'About', link: 'about', type: PageLinkType.INTERNAL, translationKey: 'nav.about' } as IPageLink
]

const navigationLinksMobile: IPageLink[] = [
	{ text: 'Home', link: '', type: PageLinkType.INTERNAL, translationKey: 'nav.home' } as IPageLink,
	{ text: 'Doggies', link: 'doggies', type: PageLinkType.INTERNAL, translationKey: 'nav.doggies' } as IPageLink,
    { text: 'Buddies', link: 'buddies', type: PageLinkType.INTERNAL, translationKey: 'nav.buddies' } as IPageLink,
	{ text: 'Meetups', link: 'meetups', type: PageLinkType.INTERNAL, translationKey: 'nav.meetups' } as IPageLink,
	{ text: 'Services', link: 'services', type: PageLinkType.INTERNAL, translationKey: 'nav.services' } as IPageLink,
	{ text: 'About', link: 'about', type: PageLinkType.INTERNAL, translationKey: 'nav.about' } as IPageLink
]

export default function Render() {

	const location = useLocation()
	const scrollDirection = useScrollDirection()

	const { breakpoints, colours } = useTheme()

	const isMobileMenu = useMediaQuery(() => breakpoints.down("md"));

	const { i18n } = useTranslation()
	let navigate = useNavigate()

	useEffect(() => {

		if (window.location.pathname === '/')
			navigate(`/${i18n.resolvedLanguage}`)

	}, [i18n.resolvedLanguage, navigate])

	return (
		<AppBar
			sx={{
				position: "sticky",
				backgroundColor: "#fff",
				boxShadow: "none",
				borderBottom: "1px solid",
				borderColor: colours.greyLight,
				boxSizing: "border-box",
				top: scrollDirection === "down" ? '-6em' : 0,
				transitionProperty: 'all',transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',transitionDuration: '500ms'
			}}
		>
			<Container maxWidth="xl">
				<Toolbar
					disableGutters
					sx={{
						minHeight: { xs: "unset", md: 96 },
						[`${breakpoints.up("xs")} and (orientation: landscape)`]: {
							minHeight: { xs: "unset", md: 96 },
						},
						[breakpoints.up("sm")]: {
							minHeight: { xs: "unset", md: 96 },
						},
					}}
				>
					{isMobileMenu ? (
						<HeaderMobile accountLinks={[]} navigationLinks={navigationLinksMobile} userDisplayName={""} currentRoute={location.pathname} />
					) : (
						<HeaderDesktop accountLinks={[]} navigationLinks={navigationLinks} userDisplayName={""} currentRoute={location.pathname} />
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
}