import { TFunction } from 'i18next'
import { setLocale } from 'yup'

export function yupLocale(_: unknown, t: TFunction): void {
    setLocale({
        array: {
             min: t('form.error.array.min')
        },
        boolean: {
            
        },
        date: {
            max: t('form.error.date.max'),
            min: t('form.error.date.min')
        },
        mixed: {
            required: t('form.error.mixed.required')
        },
        number: {
            min: t('form.error.number.min'),
            max: t('form.error.number.max')
        },
        object: {
            
        },
        string: {
            max: t('form.error.string.max'),
            min: t('form.error.string.min')
        },
        tuple: {
            
        }
    })
}