import React, {Suspense, useEffect, useState} from 'react'
import { Box, Container } from '@mui/material'
import {Route, Routes, useLocation} from 'react-router-dom'
import Footer from './app/components/chrome/footer/footer'
import Header from './app/components/chrome/header-public/header'
import Root from './root'
import Tour from './app/components/tour/tour'
import localeDe from "date-fns/locale/de";
import {useTranslation} from "react-i18next";
import localeFr from "date-fns/locale/fr-CH";
import localeEn from "date-fns/locale/en-GB";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

const ContentImprintPage = React.lazy(() => import('./app/components/content/imprint/imprint'))
const ContentPrivacyPolicyPage = React.lazy(() => import('./app/components/content/privacy/privacy'))
const ContentTermsConditionsPage = React.lazy(() => import('./app/components/content/terms-conditions/terms-conditions'))

const ContentHowYouCanHelpPage = React.lazy(() => import('./app/components/content/how-you-can-help/how-you-can-help'))

const ContentMarketingHomePage = React.lazy(() => import('./app/components/content/marketing/home'))
const ContentMarketingAboutPage = React.lazy(() => import('./app/components/content/marketing/about'))
const ContentMarketingBuddiesPage = React.lazy(() => import('./app/components/content/marketing/buddies'))
const ContentMarketingDoggiesPage = React.lazy(() => import('./app/components/content/marketing/doggies'))
const ContentMarketingMeetupsPage = React.lazy(() => import('./app/components/content/marketing/meetups'))
const ContentMarketingServicesPage = React.lazy(() => import('./app/components/content/marketing/services'))

const AuthAccountVerificationPage = React.lazy(() => import('./app/components/auth/account-verification'))

export default function Render() {
    
    const { pathname } = useLocation()

    // on page change (path) scroll to top
    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, [pathname])

    const [locale, setLocale] = useState<Locale>(localeDe)

    const { i18n } = useTranslation()

    useEffect(() => {
        const locale =
            i18n.language == 'fr'
                ? localeFr
                : i18n.language == 'en'
                    ? localeEn
                    : localeDe

        setLocale(locale)
    }, [i18n.language])

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                minHeight: '100vh',
                wordBreak: 'auto-phrase'
            }}
        >
            <Header />
            <Container component='main' role='main' maxWidth={false} disableGutters sx={{ display: 'flex', flexDirection:'column', flexGrow: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                    <Suspense fallback={<></>}>
                        <Routes>
                            <Route path='/:locale?' element={<ContentMarketingHomePage />} />
                            <Route path='/:locale?/about' element={<ContentMarketingAboutPage />} />
                            <Route path='/:locale?/buddies' element={<ContentMarketingBuddiesPage />} />
                            <Route path='/:locale?/doggies' element={<ContentMarketingDoggiesPage />} />
                            <Route path='/:locale?/meetups' element={<ContentMarketingMeetupsPage />} />
                            <Route path='/:locale?/services' element={<ContentMarketingServicesPage />} />

                            <Route path='/:locale?/imprint' element={<ContentImprintPage />} />
                            <Route path='/:locale?/privacy-policy' element={<ContentPrivacyPolicyPage />} />
                            <Route path='/:locale?/terms-conditions' element={<ContentTermsConditionsPage />} />

                            <Route path='/:locale?/how-you-can-help' element={<ContentHowYouCanHelpPage />} />
                            
                            <Route path='/:locale?/account-verification' element={<AuthAccountVerificationPage />} />
                        </Routes>
                    </Suspense>
                </LocalizationProvider>
            </Container>                            
            <Footer />
            <Tour />
        </Box>
    )
}