import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setLocale } from './features/user-state'

export function useShowTranslationKeys() {
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()

    useMemo(() => {
        const isTranslationMode = !!searchParams.get('translationMode')

        if (isTranslationMode){
            dispatch(setLocale('cimode')) 
        }
    }, [searchParams, dispatch])
}