import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import localeDe from 'date-fns/locale/de'
import localeEn from 'date-fns/locale/en-GB'
import localeFr from 'date-fns/locale/fr-CH'
import { useTranslation } from 'react-i18next'
import {Feed} from "@mui/icons-material";

const DashboardPage = React.lazy(() => import('./app/components/dashboard-page/dashboard-page'))
const AccountPage = React.lazy(() => import('./app/components/account-page/account-page'))
const BuddySearchPage = React.lazy(() => import('./app/components/buddy-search-page/buddy-search-page'))
const DoggySearchPage = React.lazy(() => import('./app/components/doggy-search-page/doggy-search-page'))
const DoggyProfileEditPage = React.lazy(() => import('./app/components/doggy-profile-edit-page/doggy-profile-edit-page'))
const DoggyProfileViewPage = React.lazy(() => import('./app/components/doggy-profile-view-page/doggy-profile-view-page'))
const MessagesPage = React.lazy(() => import('./app/components/messaging-page/messaging-page'))
const UserProfileEditPage = React.lazy(() => import('./app/components/user-profile-edit-page/user-profile-edit-page'))
const UserProfileViewPage = React.lazy(() => import('./app/components/user-profile-view-page/user-profile-view-page'))
const WizardPage = React.lazy(() => import('./app/components/wizard-page/wizard-page'))
const FeedbackPage = React.lazy(() => import('./app/components/feedback-page/feedback-page'))

const ContentImprintPage = React.lazy(() => import('./app/components/content/imprint/imprint'))
const ContentPrivacyPolicyPage = React.lazy(() => import('./app/components/content/privacy/privacy'))
const ContentTermsConditionsPage = React.lazy(() => import('./app/components/content/terms-conditions/terms-conditions'))

const ContentPerfectIntroductionPage = React.lazy(() => import('./app/components/content/perfect-introduction/perfect-introduction'))
const ContentCantonalInformationPage = React.lazy(() => import('./app/components/content/cantonal-information/cantonal-information'))
const ContentCommunityGuidelinesPage = React.lazy(() => import('./app/components/content/community-guidelines/community-guidelines'))
const ContentHowYouCanHelpPage = React.lazy(() => import('./app/components/content/how-you-can-help/how-you-can-help'))

const ContentMarketingHomePage = React.lazy(() => import('./app/components/content/marketing/home'))
const ContentMarketingDoggiesPage = React.lazy(() => import('./app/components/content/marketing/doggies'))
const ContentMarketingBuddiesPage = React.lazy(() => import('./app/components/content/marketing/buddies'))
const ContentMarketingMeetupsPage = React.lazy(() => import('./app/components/content/marketing/meetups'))
const ContentMarketingServicesPage = React.lazy(() => import('./app/components/content/marketing/services'))
const ContentMarketingAboutPage = React.lazy(() => import('./app/components/content/marketing/about'))
const ContentRoadmapPage = React.lazy(() => import('./app/components/content/roadmap/roadmap'))

export default function Render() {
    
    const [locale, setLocale] = useState<Locale>(localeDe)
    
    const { i18n } = useTranslation()

    useEffect(() => {
        const locale =
            i18n.language == 'fr-CH'
                ? localeFr
                : i18n.language == 'en-GB'
                    ? localeEn
                    : localeDe

        setLocale(locale)
    }, [i18n.language])
    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <Suspense fallback={<></>}>
                <Routes>
                    <Route path='/dashboard' element={<DashboardPage />} />
                    <Route path='/account' element={<AccountPage />} />
                    <Route path='/buddy/search' element={<BuddySearchPage />} />
                    <Route path='/doggy/search/:cursor?' element={<DoggySearchPage />} />
                    <Route path='/doggy/profile/:id' element={<DoggyProfileViewPage />} />
                    <Route path='/doggy/profile/edit/:id?' element={<DoggyProfileEditPage />} />                                       
                    <Route path='/messages/:id?' element={<MessagesPage />} />
                    <Route path='/user/profile/:id' element={<UserProfileViewPage />} />
                    <Route path='/user/profile/edit/:id' element={<UserProfileEditPage />} />
                    <Route path='/wizard' element={<WizardPage />} />
                    <Route path='/feedback' element={<FeedbackPage />} />
                    
                    <Route path='/:locale?' element={<ContentMarketingHomePage />} />
                    <Route path='/:locale?/about' element={<ContentMarketingAboutPage />} />
                    <Route path='/:locale?/buddies' element={<ContentMarketingBuddiesPage />} />
                    <Route path='/:locale?/doggies' element={<ContentMarketingDoggiesPage />} />
                    <Route path='/:locale?/meetups' element={<ContentMarketingMeetupsPage />} />
                    <Route path='/:locale?/services' element={<ContentMarketingServicesPage />} />
                    <Route path='/roadmap' element={<ContentRoadmapPage />} />
                    
                    <Route path='/perfect-introduction' element={<ContentPerfectIntroductionPage />} />
                    <Route path='/cantonal-information' element={<ContentCantonalInformationPage />} />
                    <Route path='/community-guidelines' element={<ContentCommunityGuidelinesPage />} />
                    
                    <Route path='/:locale?/imprint' element={<ContentImprintPage />} />
                    <Route path='/:locale?/privacy-policy' element={<ContentPrivacyPolicyPage />} />
                    <Route path='/:locale?/terms-conditions' element={<ContentTermsConditionsPage />} />
                    
                    <Route path='/:locale?/how-you-can-help' element={<ContentHowYouCanHelpPage />} />
                </Routes>
            </Suspense>
        </LocalizationProvider>
    )
}