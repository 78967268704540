export interface IPageLink {
    alias: string,
    text: string,
    link: string,
    type: PageLinkType,
    iconUrl?: string,
    onClick?: () => void,
    dataTourId? : string
}

export enum PageLinkType{
    INTERNAL,
    EXTERNAL
}