import React, { useEffect, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { de, frCH, enGB } from 'date-fns/locale'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import getUserLocale from 'get-user-locale'
import { Box, CircularProgress, CssBaseline, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Footer from './app/components/chrome/footer/footer'
import Header from './app/components/chrome/header/header'
import { setLocale, setUserDisplayImage, setUserDisplayName } from './app/features/user-state'
import { tryCreateUserDocument } from './app/graphql'
import Root from './root'
import { yupLocale } from './yup-locale'
import { TourProvider } from './app/tour-context'
import Tour from './app/components/tour/tour'

export default function Render() {

    const { user: authUser } = useAuth0()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { t } = useTranslation()
    
    const browserLocale = getUserLocale() // e.g. 'de-DE'

    const [tryCreateUser, { 
        data: tryCreateUserData, 
        loading: tryCreateUserLoading 
    }] = useMutation(tryCreateUserDocument)

    // on page change (path) scroll to top
    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, [pathname])

    // load or create user
    useMemo(() => {
        tryCreateUser({
            variables: {
                input: {
                    displayName: authUser?.name ?? '',
                    locale: authUser?.locale ?? browserLocale
                }
            }
        })
        .then((response) =>
        {
            yupLocale(null, t)
            
            const user = response.data?.tryCreateUser?.user
            
            if (user){
                dispatch(setLocale(user.locale))
                dispatch(setUserDisplayName(user.displayName))
                dispatch(setUserDisplayImage(user.imageProfile?.urlThumbnail))
                
                switch(user.locale) {
                    case 'en-GB': {
                        setDefaultOptions({ locale: enGB })
                        break;
                    }
                    case 'de-CH': {
                        setDefaultOptions({ locale: de })
                        break;
                    }
                    case 'fr-CH': {
                        setDefaultOptions({ locale: frCH })
                        break;
                    }
                }
            }
        })
    }, [tryCreateUser])
    
    useEffect(() => {
        yupLocale(null, t)
    }, [t])
    
    if (tryCreateUserLoading || !tryCreateUserData)
        return (
            <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', width:'100%', alignItems: 'center' }}>
                <CssBaseline />
                <CircularProgress />
            </Box>
        )

    return (
        <TourProvider>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    minHeight: '100vh',
                    wordBreak: 'auto-phrase'
                }}
            >
                <Header />
                <Container component='main' role='main' maxWidth={false} disableGutters sx={{ display: 'flex', flexDirection:'column', flexGrow: 1, minHeight: { xs: '100vh', md: '80vh' } }}>
                    <Root />
                </Container>                            
                <Footer />
                <Tour />
            </Box>
        </TourProvider>
    )
}