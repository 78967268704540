import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
    displayName: string
    displayImage: string
    locale: string
}

const initialState: UserState = {
    displayName: '',
    displayImage: '/images/placeholder-avatar.svg',
    locale: ''
}

export const userState = createSlice({
    name: 'userState',
    initialState,
    reducers: {
        setLocale: (state, data: PayloadAction<string | undefined | null>) => {
            if (data.payload)
            {
                state.locale = data.payload
            }
        },
        setUserDisplayName: (state, data: PayloadAction<string | undefined | null>) => {
            if (data.payload)
            {
                state.displayName = data.payload
            }
        },
        setUserDisplayImage: (state, data: PayloadAction<string | undefined | null>) => {
            if (data.payload)
            {
                state.displayImage = data.payload
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { 
    setLocale, 
    setUserDisplayName, 
    setUserDisplayImage 
} = userState.actions

export default userState.reducer