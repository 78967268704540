import * as Types from '../../../../types/types.t.d';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type userBySignedInIdWithSubscriptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type userBySignedInIdWithSubscriptionQuery = { __typename?: 'Query', userBySignedInId?: { __typename?: 'User', id: any, isBuddy: boolean, isOwner: boolean, subscriptionValidUntil?: any | null, subscription: { __typename?: 'UserSubscription', slug: string }, wizardStatus: { __typename?: 'WizardStatus', total: number } } | null };

export type UserCurrentSubscriptionFragment = { __typename?: 'User', id: any, isBuddy: boolean, isOwner: boolean, subscriptionValidUntil?: any | null, subscription: { __typename?: 'UserSubscription', slug: string }, wizardStatus: { __typename?: 'WizardStatus', total: number } };

export type subscriptionsAvailableForPurchaseQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type subscriptionsAvailableForPurchaseQuery = { __typename?: 'Query', subscriptionsAvailableToPurchase: Array<{ __typename?: 'UserSubscription', id: any, slug: string, name: string }> };

export type SubscriptionItemFragment = { __typename?: 'UserSubscription', id: any, slug: string, name: string };

export const UserCurrentSubscriptionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserCurrentSubscription"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isBuddy"}},{"kind":"Field","name":{"kind":"Name","value":"isOwner"}},{"kind":"Field","name":{"kind":"Name","value":"subscription"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}},{"kind":"Field","name":{"kind":"Name","value":"subscriptionValidUntil"}},{"kind":"Field","name":{"kind":"Name","value":"wizardStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"total"}}]}}]}}]} as unknown as DocumentNode<UserCurrentSubscriptionFragment, unknown>;
export const SubscriptionItemFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SubscriptionItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserSubscription"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<SubscriptionItemFragment, unknown>;
export const userBySignedInIdWithSubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userBySignedInIdWithSubscription"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userBySignedInId"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserCurrentSubscription"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserCurrentSubscription"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isBuddy"}},{"kind":"Field","name":{"kind":"Name","value":"isOwner"}},{"kind":"Field","name":{"kind":"Name","value":"subscription"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}},{"kind":"Field","name":{"kind":"Name","value":"subscriptionValidUntil"}},{"kind":"Field","name":{"kind":"Name","value":"wizardStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"total"}}]}}]}}]} as unknown as DocumentNode<userBySignedInIdWithSubscriptionQuery, userBySignedInIdWithSubscriptionQueryVariables>;
export const subscriptionsAvailableForPurchaseDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"subscriptionsAvailableForPurchase"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subscriptionsAvailableToPurchase"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SubscriptionItem"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SubscriptionItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserSubscription"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<subscriptionsAvailableForPurchaseQuery, subscriptionsAvailableForPurchaseQueryVariables>;