import React, { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
//import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'
//import Tooltip from '@mui/material/Tooltip'
import Select from "@mui/material/Select";
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'

import { availableLanguages } from "../../../../../i18n";
import { IPageLink } from '../constants'
import {useAuth0} from "@auth0/auth0-react";
import Avatar from "@mui/material/Avatar";

interface Props {
    accountLinks: IPageLink[],
    navigationLinks: IPageLink[],
    userDisplayName?: string,
    userEmail?: string,
    currentRoute: string
}

export default function Render(props: Props) {

    const { loginWithRedirect } = useAuth0()
    const { t, i18n } = useTranslation()
    const { colours } = useTheme()
	const navigate = useNavigate()

    const [showDrawer, setShowDrawer] = useState<null | string>(null)

	const language = useMemo(() => {
		return i18n.language === 'dog' ? i18n.language : i18n.resolvedLanguage
	}, [i18n.language, i18n.resolvedLanguage])

    const handleLanguageChanged = useCallback((language: string) => {
        i18n.changeLanguage(language)
        
        navigate(window.location.pathname
            .replace('/fr', `/${language}`)
            .replace('/en', `/${language}`)
            .replace('/de', `/${language}`)
            .replace('/dog', `/${language}`))
    }, [i18n, navigate]);
    
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', py: 1, width: '100%' }}>
                <Typography component={Link} to={`/${i18n.resolvedLanguage}`} sx={{ flexGrow: 1 }}>
                    <Box
                        component='img'
                        src='/images/logo.svg' 
                        sx={{ width: '121px', height: '36px', display: 'block' }}
                        alt="Home"
                        width="121" height="36"
                    />
                </Typography>
                
                <Select
                    sx={{ 
                        boxShadow: 'none', 
                        '.MuiOutlinedInput-notchedOutline': { border: 0 }
                    }}
                    startAdornment={<Box
                        component='img'
                        src='/images/icons/language.svg'
                        sx={{ width: '24px', height: '24px', display: 'block', mr: '10px' }}
                        alt=""
                        width="24" height="24"
                    />}
                    variant="outlined"
                    value={language}
                    onChange={(e) => handleLanguageChanged(e.target.value)}
                >
                    {availableLanguages.map((availableLanguage) => (
                        <MenuItem key={availableLanguage} value={availableLanguage}>{availableLanguage.split("-")[0].toUpperCase()}</MenuItem>
                    ))}
                </Select>

                <IconButton
                    onClick={() => loginWithRedirect()}
                    sx={{
                        p: 0,
                        mr: 2,
                        ml: 1
                    }}
                    style={{ backgroundColor: 'transparent' }}
                    data-tour-id='tour-header-account'
                >
                    <Avatar
                        sx={{ width: 35, height: 35 }}
                        src={'/images/placeholder-avatar.svg'}
                    />
                </IconButton>
                
                <IconButton size='large' onClick={() => setShowDrawer('navigationMenu')} color='primary' sx={{ width: '50px', alignSelf: 'flex-center', flexGrow: 0 }}>
                    <Box
                        component='img'
                        src='/images/icons/menu-open.svg' 
                        sx={{ width: '24px', height: '24px', fill: colours.greenDark }}
                        alt="Open menu"
                        width="24" height="24"
                    />
                </IconButton>
            </Box>
            <SwipeableDrawer
                anchor='left'
                open={showDrawer !== null}
                onClose={() => setShowDrawer(null)}
                onOpen={() => setShowDrawer(null)}
                PaperProps={{ sx: { width: '85%' } }}
                disableSwipeToOpen
            >
                <Grid container sx={{ width: '100%', borderBottom: '1px solid', borderBottomColor: colours.greyLight }}>
                    <Grid xs sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                        <Typography component={Link} to={`/${i18n.resolvedLanguage}`} sx={{ flexGrow: 1 }} onClick={() => setShowDrawer(null)}>
                            <Box
                                component='img'
                                src='/images/logo.svg' 
                                sx={{ width: '121px', height: '36px', display: 'block' }}
                                width="121" height="36"
                            />
                        </Typography>
                    </Grid>
                    <Grid xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', p: 2 }}>
                        <IconButton size='large' onClick={() => setShowDrawer(null)} color='primary'>
                            <Box
                                component='img'
                                src='/images/icons/close.svg' 
                                sx={{ width: '24px', height: '24px', fill: colours.greenDark }}
                                alt=""
                                width="24" height="24"
                            />
                        </IconButton>
                    </Grid>
                </Grid>
                    
                { showDrawer === 'navigationMenu' && ( 
                    props.navigationLinks.map((page) => { 
                        const isCurrentPage = props.currentRoute === page.link

                        return (
                            <MenuItem 
                                component={Link}
                                to={`/${i18n.resolvedLanguage}/${page.link}`}
                                key={page.text} 
                                onClick={() => setShowDrawer(null)}
                                sx={{ borderBottom: '1px solid', borderBottomColor: colours.greyLight, backgroundColor: isCurrentPage ? colours.greenLight : 'transparent',
                                '&:hover': {
                                    backgroundColor: colours.greenLight,
                                }
                            }}
                            >
                                <Typography textAlign='center' sx={{ fontSize: 16, color: colours.greyDark, py: 2, fontWeight: isCurrentPage ? 500 : 400 }}>{t(page.translationKey)}</Typography>
                            </MenuItem>
                        )
                    })
                )}
                {/* { showDrawer === 'accountMenu' && ( 
                    <>
                        <Box sx={{ width: '100%', display: 'flex', gap: 2, p: 2, alignItems: 'center', my: 2 }}>
                            <Avatar alt={props.userDisplayName} sx={{ width: 46, height: 46 }} src='/images/placeholder-avatar.svg' />
                            <Typography component={'span'} 
                                sx={{
                                    color: colours.greyDark, size: 16, fontWeight: 500
                                }}
                            >
                                {props.userDisplayName}
                            </Typography>
                        </Box>
                        
                        { props.accountLinks.map((setting) => {
                            const isCurrentPage = props.currentRoute === setting.link

                            return ( 
                                <>
                                    {
                                        setting.type === PageLinkType.INTERNAL && (
                                            <MenuItem key={setting.text} onClick={() => setShowDrawer(null)} component={Link} to={setting.link} sx={{
                                                backgroundColor: isCurrentPage ? colours.greenLight : 'transparent',
                                                '&:hover': {
                                                    backgroundColor: colours.greenLight,
                                                } 
                                            }}>
                                                <Typography 
                                                    sx={{ textDecoration: 'none', color: colours.greenDark, fontWeight: 400, fontSize: 16, py: 2, width: '100%' }}
                                                    textAlign='center'>
                                                        <Grid container spacing={1}>
                                                            <Grid><Avatar sx={{ width: 24, height: 24 }} src={setting.iconUrl} /></Grid>
                                                            <Grid>{setting.text}</Grid>
                                                        </Grid>           
                                                </Typography>
                                            </MenuItem>
                                        )
                                    }
                                    {
                                        setting.type === PageLinkType.EXTERNAL && (
                                            <MenuItem key={setting.text} onClick={() => setShowDrawer(null)} component='a' href={setting.link} sx={{
                                                '&:hover': {
                                                    backgroundColor: colours.greenLight,
                                                } 
                                            }}>
                                                <Typography 
                                                    sx={{ textDecoration: 'none', color: colours.greyDark, fontWeight: 400, fontSize: 16, py: 2, width: '100%' }}
                                                    textAlign='center'>
                                                        <Grid container spacing={1}>
                                                            <Grid><Avatar sx={{ width: 24, height: 24 }} src={setting.iconUrl} /></Grid>
                                                            <Grid>{setting.text}</Grid>
                                                        </Grid>
                                                </Typography>
                                            </MenuItem>
                                        )
                                    }
                                </>
                            )
                        })}
                    </>
                )} */}
            </SwipeableDrawer>
        </>
    )
}
