import React, { createContext, useContext, useMemo } from 'react'
import { Step } from 'react-joyride'
import { useSetState } from 'react-use'

export interface AppState {
    run: boolean
    stepIndex: number
    steps: Step[]
}

const appState = {
    run: false,
    stepIndex: 0,
    steps: []
}

export const AppContext = createContext({
    tourState: appState,
    setTourState: () => undefined,
})
AppContext.displayName = 'AppContext'

export function TourProvider(props: any) {
    const [tourState, setTourState] = useSetState(appState)

    const value = useMemo(
        () => ({
            tourState,
            setTourState,
        }),
        [setTourState, tourState],
    )

    return <AppContext.Provider value={value} {...props} />
}

export function useAppContext(): {
    setTourState: (patch: Partial<AppState> | ((previousState: AppState) => Partial<AppState>)) => void
    tourState: AppState
} {
    const context = useContext(AppContext)

    if (!context) {
        throw new Error('useAppContext must be used within a AppProvider')
    }

    return context
}
