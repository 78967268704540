import React, {useEffect, useMemo, useState} from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import {format} from "date-fns";
import {Link, useLocation} from 'react-router-dom'
import ReactGA from "react-ga4";
import {Button, Dialog, DialogContent, IconButton, List, ListItem, ListItemText, Snackbar, Stack, Switch} from '@mui/material'
import {useAuth0} from "@auth0/auth0-react";
import Grid from "@mui/material/Unstable_Grid2";

export default function Render() {


    const { i18n, t } = useTranslation()
    const { colours } = useTheme()

    const {
        isAuthenticated: auth0IsAuthenticated,
        isLoading: auth0IsLoading
    } = useAuth0()
    const location = useLocation();
    
    const isCookiePreferencesSet = window.localStorage.getItem('cookies_accepted')

    const [cookiesOptedIn, setCookiesOptedIn] = useState(true)
    const [cookiesEnabled, setCookiesEnabled] = useState(isCookiePreferencesSet === 'true')
    const [cookieBanerOpen, setCookieBannerOpen] = useState(isCookiePreferencesSet === null)
    const [cookiesManageOpen, setCookiesManageOpen] = useState(false)

    // if cookie: cookies_accepted isn't set or has a vaule of false we init GA with cookies disabled
    // if cookie: cookies_accepted is set and the value is true we init GA with cookies enabled
    useMemo(() => {
        // set GA to use no cookies
        ReactGA.gtag( 'consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': cookiesEnabled ? 'granted' : 'denied'
        })

        ReactGA.initialize([{
            trackingId: "G-PSNKDHY0SV"
        }])
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: location.pathname, anonymizeIp: true });
    }, [location]);

    const cookieHandleAccept = (event: React.SyntheticEvent | Event) => {
        // set GA to use cookies
        ReactGA.gtag( 'consent', 'update', {
            'analytics_storage': 'granted'
        })

        // prevent cookie banner opening again and set if cookies are accepted
        window.localStorage.setItem('cookies_accepted', 'true')

        // enable cookies
        setCookiesEnabled(true)

        // close cookie manage
        setCookieBannerOpen(false)
        setCookiesManageOpen(false)
    }

    const cookieHandleSave = (event: React.SyntheticEvent | Event) => {
        // set GA to use cookies
        ReactGA.gtag( 'consent', 'update', {
            'analytics_storage': cookiesOptedIn ? 'granted' : 'denied'
        })

        // prevent cookie banner opening again and set if cookies are accepted
        window.localStorage.setItem('cookies_accepted', cookiesOptedIn ? 'true' : 'false')

        // enable cookies
        setCookiesEnabled(cookiesOptedIn)

        // close cookie manage
        setCookieBannerOpen(false)
        setCookiesManageOpen(false)
    }

    const cookieHandleManageOpen = (event: React.SyntheticEvent | Event) => {
        setCookieBannerOpen(false)
        setCookiesManageOpen(true)
    }

    const publicRoutes = [
        { path: '/:locale?'},
        { path: '/:locale?/about'},
        { path: '/:locale?/buddies'},
        { path: '/:locale?/doggies'},
        { path: '/:locale?/meetups'},
        { path: '/:locale?/services'},
        { path: '/:locale?/roadmap'},
        { path: '/:locale?/perfect-introduction'},
        { path: '/:locale?/cantonal-information'},
        { path: '/:locale?/community-guidelines'},
        { path: '/:locale?/imprint'},
        { path: '/:locale?/privacy-policy'},
        { path: '/:locale?/terms-conditions'},
        { path: '/:locale?/how-you-can-help'},
        { path: '/:locale?/dog-society'}
    ]

    const isPublic = useMemo(() => {

        if (auth0IsLoading)
        {
            return true
        }
        else if (!auth0IsAuthenticated)
        {
            return true
        }
        else
        {
            return false
        }

    }, [
        auth0IsAuthenticated,
        auth0IsLoading
    ])

    return (
        <Container 
            component='footer'
            maxWidth={false} 
            sx={{
                backgroundColor: colours.greenDark,
                marginTop: 'auto' 
            }} 
            disableGutters
        >
            <Container maxWidth='xl' sx={{ padding: 4 }}>
                <Stack
                    direction={{ 
                        xs: 'column', 
                        md: 'row' 
                    }}
                    spacing={2}
                    justifyContent="space-evenly"
                >
                    { !isPublic && (
                        <Box>
                            <Typography
                                sx={{
                                    color: colours.white,
                                    fontSize: '16px',
                                    fontWeight: 400
                                }}
                            >
                                {t('footer.guidelines')}
                            </Typography>
                            <List
                                dense={true}
                                sx={{
                                    '.MuiListItem-root': {
                                        p: 0
                                    }
                                }}
                            >
                                <ListItem>
                                    <ListItemText>
                                        <Typography
                                            to={`/perfect-introduction`}
                                            component={Link}
                                            sx={{
                                                color: 'white',
                                                textDecoration: 'none',
                                                fontSize: '13px'
                                            }}
                                        >
                                            {t('footer.perfect_intro')}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>
                                        <Typography
                                            to={`/cantonal-information`}
                                            component={Link}
                                            sx={{
                                                color: 'white',
                                                textDecoration: 'none',
                                                fontSize: '13px'
                                            }}
                                        >
                                            {t('footer.cantonal_info')}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>
                                        <Typography
                                            to={`/community-guidelines`}
                                            component={Link}
                                            sx={{
                                                color: 'white',
                                                textDecoration: 'none',
                                                fontSize: '13px'
                                            }}
                                        >
                                            {t('footer.community_guidelines')}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Box>
                    )}
                    <Box
                    >
                        <Typography
                            sx={{
                                color: colours.white,
                                fontSize: '16px',
                                fontWeight: 400
                            }}
                        >
                            {t('footer.about_us')}
                        </Typography>
                        <List 
                            dense={true}
                            sx={{
                                '.MuiListItem-root': {
                                    p: 0
                                }
                            }}
                        >
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}`}
                                        component={Link}
                                        sx={{
                                            color: 'white',
                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        Home
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}/doggies`}
                                        component={Link} 
                                        sx={{
                                            color: 'white', 
                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        Doggies
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}/buddies`}
                                        component={Link}
                                        sx={{
                                            color: 'white',
                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        Buddies
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}/meetups`}
                                        component={Link}
                                        sx={{
                                            color: 'white',
                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        Meetups
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}/services`}
                                        component={Link}
                                        sx={{
                                            color: 'white',
                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        {t('footer.services')}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}/about`}
                                        component={Link}
                                        sx={{
                                            color: 'white',
                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        {t('footer.about_general')}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            {
                                !isPublic && (
                                    <ListItem>
                                        <ListItemText>
                                            <Typography
                                                to={`/roadmap`}
                                                component={Link}
                                                sx={{
                                                    color: 'white',
                                                    textDecoration: 'none',
                                                    fontSize: '13px'
                                                }}
                                            >
                                                {t('footer.roadmap')}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>       
                                )
                            }
                            {
                                !isPublic && (
                                    <ListItem>
                                        <ListItemText>
                                            <Typography
                                                to={`/feedback`}
                                                component={Link}
                                                sx={{
                                                    color: 'white',
                                                    textDecoration: 'none',
                                                    fontSize: '13px'
                                                }}
                                            >
                                                {t('footer.feedback')}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                )
                            }
                        </List>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: colours.white,
                                fontSize: '16px',
                                fontWeight: 400
                            }}
                        >
                                        {t('footer.help')}
                        </Typography>
                        <List 
                            dense={true}
                            sx={{
                                '.MuiListItem-root': {
                                    p: 0
                                }
                            }}
                        >
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}/how-you-can-help`}
                                        component={Link}
                                        sx={{
                                            color: 'white',

                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        {t('footer.how_help')}
                                    </Typography>     
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: colours.white,
                                fontSize: '16px',
                                fontWeight: 400
                            }}
                        >
                            {t('footer.legals')}
                        </Typography>
                        <List
                            dense={true}
                            sx={{
                                '.MuiListItem-root': {
                                    p: 0
                                }
                            }}
                        >
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}/imprint`}
                                        component={Link}
                                        sx={{
                                            color: 'white',

                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        {t('footer.impressum')}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}/privacy-policy`}
                                        component={Link}
                                        sx={{
                                            color: 'white',

                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        {t('home.footer.privacy')}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        to={`/${i18n.resolvedLanguage}/terms-conditions`}
                                        component={Link}
                                        sx={{
                                            color: 'white',
                                            textDecoration: 'none',
                                            fontSize: '13px'
                                        }}
                                    >
                                        {t('footer.terms_and_conditions')}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography
                                        component={IconButton}
                                        disableRipple
                                        onClick={() => setCookiesManageOpen(true)}
                                        sx={{
                                            color: 'white',
                                            textDecoration: 'none',
                                            fontSize: '13px',
                                            p: 0
                                        }}
                                    >
                                        {t('footer-link-manage-cookies')}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Box>
                </Stack>

                <Box sx={{
                    display: 'flex',
                    justifyContent: {xs: 'center', sm: 'space-between'},
                    flexDirection: {xs: 'column-reverse', sm: 'row'},
                    mt: 8
                }}>
                    <Typography component='p' sx={{
                        color: colours.white,
                        fontWeight: 400,
                        fontSize: 16,
                        textAlign: {xs: 'center', sm: 'left'},
                        py: {xs: 2, sm: 1}
                    }}>
                        &copy; {format(new Date(), 'yyyy')} DoggyBuddies	
                    </Typography>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1px'}}>
                        <MenuItem>
                            <Typography component='a' href="https://www.instagram.com/doggybuddiesch/" target="_blank">
                                <Box component='img' src='/images/icons/instagram.svg'
                                     sx={{width: '24px', height: '24px', display: 'block'}}/>
                            </Typography>
                        </MenuItem>
                        <MenuItem>
                            <Typography component="a" href="https://www.facebook.com/doggybuddiesch" target="_blank">
                                <Box component='img' src='/images/icons/facebook.svg' sx={{ width: '24px', height: '24px', display: 'block' }} />
                            </Typography>
                        </MenuItem>
                        <MenuItem>
                            <Typography component="a" href="https://www.linkedin.com/company/doggybuddiesch" target="_blank">
                                <Box component='img' src='/images/icons/linkedin.svg' sx={{ width: '24px', height: '24px', display: 'block' }} />
                            </Typography>
                        </MenuItem>
                    </Box>
                </Box>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={cookieBanerOpen}
                    sx={{
                        maxWidth: { sm: '400px' },
                        ".MuiSnackbarContent-root": {
                            p: 3,
                            backgroundColor: colours.greenDark
                        },
                        ".MuiSnackbarContent-message" : {
                            p: 0
                        },
                        ".MuiSnackbarContent-message p" : {
                            mt: 0
                        }
                    }}
                    message={
                        <>
                            <Typography
                                component='p'
                                sx={{
                                    fontSize: 18,
                                    mb: 3,
                                    fontWeight: 400
                                }}
                            >
                                {t('cookie.banner.text')}
                            </Typography>
                            <Button color="primary" size="small" onClick={(event) => cookieHandleAccept(event)}
                                    sx={{
                                        fontSize: 16,
                                        textTransform: 'none',
                                        py: 1,
                                        px: 2,
                                        backgroundColor: colours.greenLight,
                                        color: colours.greyDark,
                                        "&:hover": {
                                            backgroundColor: colours.greenLight
                                        }
                                    }}
                            >
                                {t('cookie-banner.button.accept')}
                            </Button>
                            <Button color="secondary" size="small" onClick={(event) => cookieHandleManageOpen(event)}
                                    sx={{
                                        fontSize: 16,
                                        textTransform: 'none',
                                        py: 1,
                                        px: 2,
                                        backgroundColor: colours.white,
                                        color: colours.greyDark,
                                        "&:hover": {
                                            backgroundColor: colours.greenLight
                                        },
                                        ml: 2
                                    }}
                            >
                                {t('cookie-banner.button.manage')}
                            </Button>
                        </>
                    }

                />
                <Dialog open={cookiesManageOpen} maxWidth='md'>
                    <DialogContent>
                        <Typography
                            component={'h1'}
                            variant="h1"
                            sx={{
                                fontSize: "32px",
                                mt:0,
                                mb: 2,
                                color: colours.greyDark,
                                textDecoration: "none",
                                textTransform: 'none',
                                padding: 0
                            }}
                        >
                            {t('cookie-preferences.title.cookies')}
                        </Typography>
                        <Typography
                            sx={{
                                mb: 2
                            }}
                        >
                            {t('cookie-preferences.description')}
                        </Typography>
                        <Button
                            onClick={(event) => cookieHandleAccept(event)}
                            sx={{
                                background: colours.greenDark,
                                color: colours.white,
                                mb: 2,
                                "&:hover": {
                                    textDecoration: "underline",
                                    background: colours.greenDark,
                                    color: colours.greenLight,
                                }
                            }}
                        >
                            {t('cookie-preferences.button.accept')}
                        </Button>
                        <Typography
                            component={'h1'}
                            variant="h1"
                            sx={{
                                fontSize: "24px",
                                mt:4,
                                mb: 4,
                                color: colours.greyDark,
                                textDecoration: "none",
                                textTransform: 'none',
                                padding: 0
                            }}
                        >
                            {t('cookie-preferences.title.preferences')}
                        </Typography>
                        <Grid container rowSpacing={2}>
                            <Grid xs={10}>
                                <Typography
                                    component={'h1'}
                                    variant="h1"
                                    sx={{
                                        fontSize: "18px",
                                        fontWeight: 500,
                                        color: colours.greyDark,
                                        textDecoration: "none",
                                        textTransform: 'none',
                                        padding: 0
                                    }}
                                >
                                    {t('cookie-preferences.title.essential')}
                                </Typography>
                                <p>{t('cookie-preferences.text.essential')}</p>
                            </Grid>
                            <Grid xs={2}>
                                <Switch defaultChecked color="primary" disabled />
                            </Grid>
                            <Grid xs={10}>
                                <Typography
                                    component={'h1'}
                                    variant="h1"
                                    sx={{
                                        fontSize: "18px",
                                        fontWeight: 500,
                                        color: colours.greyDark,
                                        textDecoration: "none",
                                        textTransform: 'none',
                                        padding: 0
                                    }}
                                >
                                    {t('cookie-preferences.title.performance')}
                                </Typography>
                                <p>{t('cookie-preferences.text.performance')}</p>
                            </Grid>
                            <Grid xs={2}>
                                <Switch
                                    defaultChecked
                                    color="primary"
                                    onChange={(event) => setCookiesOptedIn(event.target.checked)}
                                    checked={cookiesOptedIn}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Button
                                    onClick={(event) => cookieHandleSave(event)}
                                    sx={{
                                        backgroundColor: colours.greenLight
                                    }}
                                >
                                    {t('cookie-preferences.save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Container>
        </Container>
    )
}