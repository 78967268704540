import React from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities'
import { useAuth0 } from '@auth0/auth0-react'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { StrictTypedTypePolicies } from './app/types/apollo-helpers'
import AppAuthentication from './app-authentication'
import { SSELink } from './graphql-sse'

export default function Render() {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const error = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            enqueueSnackbar(t('error.notification'), { variant: 'error' } )
        }

        if (networkError) {
            enqueueSnackbar(t('error.check_settings.notification'), { variant: 'error' } )
        }
    })

    const typePolicies: StrictTypedTypePolicies = {
        Query: {
            fields: {
                dogsByDistance: relayStylePagination(),
                buddiesByDistance: relayStylePagination(),
                messagesByUserIdForRecipientIdPaged: relayStylePagination(['withUserId'])
            }
        }
    }
    
    const sseLink = new SSELink({
        url: `${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_URL}`,
        headers: async () => {
            const token = await getAccessTokenSilently()
            
            return {
                Authorization: `Bearer ${token}`
            };
        },
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query)
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            )
        },
        sseLink,
        error
    )

    const uploadLink = createUploadLink({ 
        uri: `${process.env.REACT_APP_SITE_URL}/graphql/`  
    })

    const { getAccessTokenSilently } = useAuth0()

    const authMiddleware = setContext(async (_, { headers, ...context }) => {
        const token = await getAccessTokenSilently()

        return {
            headers: {
                ...headers,
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
            ...context,
        }
    })

    const client = new ApolloClient({
        link: ApolloLink.from([
            authMiddleware,
            splitLink,
            uploadLink
        ]),
        cache: new InMemoryCache({ typePolicies }),
        defaultOptions: {
            mutate: {
                errorPolicy: 'ignore'    
            }
        }
    })
    
    return (
        <ApolloProvider client={client}>
            <AppAuthentication />
        </ApolloProvider>
    )
}