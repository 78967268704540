import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { store } from './app/store'
import AppProviderTheme from './app-provider-theme'

export default function Render() {

    Sentry.init({
        dsn: 'https://c14410500a112a27ef253cbb0060a33e@o881305.ingest.us.sentry.io/4507011625713664'
    })
    
    return (
        <Auth0Provider
            domain={`${process.env['REACT_APP_AUTH_URL']}`}
            clientId={`${process.env['REACT_APP_AUTH_CLIENT_ID']}`}
            authorizationParams={{
                redirect_uri: `${process.env['REACT_APP_SITE_URL']}/dashboard`,
                audience: `${process.env['REACT_APP_AUTH_AUDIENCE']}`,
                scope: 'email profile openid',
                ui_locales: 'fr de en',
                // cacheLocation: 'localstorage',
                // useRefreshTokens: true
            }}
        >
            <SnackbarProvider
                maxSnack={1}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <Provider store={store}>
                    <React.StrictMode>
                        <BrowserRouter>
                            <AppProviderTheme />
                        </BrowserRouter>
                    </React.StrictMode>
                </Provider>
            </SnackbarProvider>
        </Auth0Provider>
    )
}