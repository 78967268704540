import * as Types from '../../../types/types.t.d';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type wizardStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type wizardStatusQuery = { __typename?: 'Query', userBySignedInId?: { __typename?: 'User', id: any, wizardStatus: { __typename?: 'WizardStatus', total: number } } | null };

export type WizardStatusFragment = { __typename?: 'WizardStatus', total: number };

export const WizardStatusFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WizardStatus"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WizardStatus"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"total"}}]}}]} as unknown as DocumentNode<WizardStatusFragment, unknown>;
export const wizardStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"wizardStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userBySignedInId"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"wizardStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WizardStatus"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WizardStatus"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WizardStatus"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"total"}}]}}]} as unknown as DocumentNode<wizardStatusQuery, wizardStatusQueryVariables>;