import React, { useCallback, useEffect, useMemo } from 'react'
import { Typography, TypographyProps, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Joyride, { ACTIONS, CallBackProps, EVENTS, Step } from 'react-joyride'
import { useNavigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { useAppContext } from '../../tour-context'
import useMediaQuery from "@mui/material/useMediaQuery";
import {useQuery} from "@apollo/client";
import {wizardStatusDocument} from "../dashboard-page/graphql/wizard-status";

export default function Tour() {

    const {
        setTourState,
        tourState: { 
            run, 
            stepIndex, 
            steps 
        },
    } = useAppContext()

    const { colours } = useTheme()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { breakpoints } = useTheme()
    const isMobileView = useMediaQuery(() => breakpoints.down('md'))

    const { data: wizardStatusData } = useQuery(wizardStatusDocument)

    const StepHeading = (props: TypographyProps) => (
        <Typography
            component='h2'
            sx={{
                fontSize: '26px',
                fontWeight: 500,
                color: colours.greyDark,
                mb: 3
            }}
        >
            {props.children}
        </Typography>
    )

    const StepText = (props: TypographyProps) => (
        <Typography
            component='p'
            sx={{
                fontSize: '16px',
                fontWeight: 400,
                color: colours.greyDark
            }}
        >
            {props.children}
        </Typography>
    )
    
    const {
        tourStepsDesktop,
        tourStepsMobile
    } = useMemo(() => {
        
        const isWizardComplete = wizardStatusData?.userBySignedInId?.wizardStatus.total === 100
        
        let tourStepsDesktop: Step[] = [
            {
                target: '[data-tour-id="tour-home-user-profile"]',
                content: (
                    <>
                        <StepHeading children={t('tour.your_profile_header')} />
                        <StepText children={t('tour.your_profile_text')} />
                    </>
                ),
                disableBeacon: true,
                disableScrolling: true,
                placement: 'right-start'
            },
            {
                target: '[data-tour-id="tour-header-doggies"]',
                content: (
                    <>
                        <StepHeading children={'Doggies'} />
                        <StepText children={t('tour.doggies_header_explainer')}/>
                    </>
                ),
                disableBeacon: true,
                disableScrolling: true,
                placement: 'bottom-end',
            },
            {
                target: '[data-tour-id="tour-doggy-filters"]',
                content: (
                    <>
                        <StepHeading children={t('tour.filtering.header')} />
                        <StepText children={t('tour.filtering.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'right-start'
            },
            {
                target: '[data-tour-id="tour-search"]',
                content: (
                    <>
                        <StepHeading children={t('tour.filtering.search_explainer')} />
                        <StepText children={t('tour.filtering.text.second_explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            },
            {
                target: '[data-tour-id="tour-search-current-location"]',
                content: (
                    <>
                        <StepHeading children={t('tour.use_current_location.header')} />
                        <StepText children={t('tour.use_current_location.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            },
            {
                target: '[data-tour-id="tour-header-buddies"]',
                content: (
                    <>
                        <StepHeading children={t('tour.buddies.header')} />
                        <StepText children={t('tour.buddies.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            },
            {
                target: '[data-tour-id="tour-header-account"]',
                content: (
                    <>
                        <StepHeading children={t('tour.account.header')} />
                        <StepText children={t('tour.account.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            },
            {
                target: '[data-tour-id="tour-wizard"]',
                content: (
                    <>
                        <StepHeading children={t('tour.setup_wizard.header')} />
                        <StepText children={t('tour.setup_wizard.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            }
        ]
        
        if (isWizardComplete)
        {
            tourStepsDesktop.pop()
        }

        const tourStepsMobile: Step[] = [
            {
                target: '[data-tour-id="tour-home-user-profile"]',
                content: (
                    <>
                        <StepHeading children={t('tour.your_profile_header')} />
                        <StepText children={t('tour.your_profile_text')} />
                    </>
                ),
                disableBeacon: true,
                disableScrolling: true,
                placement: 'right-start'
            },
            {
                target: '[data-tour-id="tour-header-doggies-mobile"]',
                content: (
                    <>
                        <StepHeading children={'Doggies'} />
                        <StepText children={t('tour.doggies_header_explainer')}/>
                    </>
                ),
                disableBeacon: true,
                disableScrolling: true,
                placement: 'bottom-end'
            },
            {
                target: '[data-tour-id="tour-doggy-filters-mobile"]',
                content: (
                    <>
                        <StepHeading children={t('tour.filtering.header')} />
                        <StepText children={t('tour.filtering.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'right-start'
            },
            {
                target: '[data-tour-id="tour-search"]',
                content: (
                    <>
                        <StepHeading children={t('tour.filtering.search_explainer')} />
                        <StepText children={t('tour.filtering.text.second_explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            },
            {
                target: '[data-tour-id="tour-search-current-location"]',
                content: (
                    <>
                        <StepHeading children={t('tour.use_current_location.header')} />
                        <StepText children={t('tour.use_current_location.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            },
            {
                target: '[data-tour-id="tour-header-doggies-mobile"]',
                content: (
                    <>
                        <StepHeading children={t('tour.buddies.header')} />
                        <StepText children={t('tour.buddies.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            },
            {
                target: '[data-tour-id="tour-header-account"]',
                content: (
                    <>
                        <StepHeading children={t('tour.account.header')} />
                        <StepText children={t('tour.account.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            },
            {
                target: '[data-tour-id="tour-wizard"]',
                content: (
                    <>
                        <StepHeading children={t('tour.setup_wizard.header')} />
                        <StepText children={t('tour.setup_wizard.explainer')} />
                    </>
                ),
                disableScrolling: true,
                placement: 'bottom'
            }
        ]

        if (isWizardComplete)
        {
            tourStepsMobile.pop()
        }
        
        setTourState({
            steps:  isMobileView ? tourStepsMobile : tourStepsDesktop
        })
        
        return {
            tourStepsDesktop,
            tourStepsMobile
        }
        
    }, [t, wizardStatusData])
        
    useMount(() => {
        setTourState({
            steps: isMobileView ? tourStepsMobile : tourStepsDesktop
        })
    })

    const handleCallback = useCallback((data: CallBackProps) => {
        const { action, index, lifecycle, type, size } = data
        
        if (action === ACTIONS.PREV && index === 1 && lifecycle === 'complete')
        {
            // home user profile
            navigate('/dashboard')
            setTourState({ stepIndex: 0 })
        }
        else if ((action === ACTIONS.NEXT && index === 0 && lifecycle === 'complete') 
            || action === ACTIONS.PREV && index === 2 && lifecycle === 'complete')
        {
            // doggy page
            navigate('/doggy/search')
            setTourState({ stepIndex: 1 })
        }
        else if ((action === ACTIONS.NEXT && index === 1 && lifecycle === 'complete')
            || action === ACTIONS.PREV && index === 3 && lifecycle === 'complete')
        {
            // doggy filters
            setTourState({ stepIndex: 2 })
        }
        else if ((action === ACTIONS.NEXT && index === 2 && lifecycle === 'complete')
            || action === ACTIONS.PREV && index === 4 && lifecycle === 'complete')
        {
            // doggy search bar
            setTourState({ stepIndex: 3 })
        }
        else if ((action === ACTIONS.NEXT && index === 3 && lifecycle === 'complete')
            || action === ACTIONS.PREV && index === 5 && lifecycle === 'complete')
        {
            // doggy current location
            navigate('/doggy/search')
            setTourState({ stepIndex: 4 })
        }
        else if ((action === ACTIONS.NEXT && index === 4 && lifecycle === 'complete')
            || action === ACTIONS.PREV && index === 6 && lifecycle === 'complete')
        {
            // buddy page
            navigate('/buddy/search')
            setTourState({ stepIndex: 5 })
        }
        else if ((action === ACTIONS.NEXT && index === 5 && lifecycle === 'complete')
            || action === ACTIONS.PREV && index === 7 && lifecycle === 'complete')
        {
            // account page
            navigate('/account')
            setTourState({ stepIndex: 6 })
        }
        else if ((action === ACTIONS.NEXT && index === 6 && lifecycle === 'complete')
            || action === ACTIONS.PREV && index === 8 && lifecycle === 'complete')
        {
            // wizard
            navigate('/dashboard')

            if (wizardStatusData?.userBySignedInId?.wizardStatus.total === 100)
            {
                setTourState({ run: false, stepIndex: 0 })
            }
            else 
            {
                setTourState({ stepIndex: 7 })
            }
        }
        else if (action === ACTIONS.RESET 
            || action === ACTIONS.CLOSE 
            || (index === size -1 && type === EVENTS.STEP_AFTER))
        {
            setTourState({ run: false, stepIndex: 0 })
        }
    }, [navigate, setTourState, wizardStatusData])
    
    return (
        <Joyride
            callback={handleCallback}
            continuous
            locale={{
                back: t('tour.button.back'),
                close: t('tour.button.close'),
                last: t('tour.setup_wizard.button.finish'),
                next: t('tour.button.next'),
                skip: t('tour.button.skip'),
            }}
            run={run}
            showProgress
            stepIndex={stepIndex}
            steps={steps}
            styles={{
                options: {
                    primaryColor: colours.greenDark
                }
            }}
        />
    )
}