import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { useTheme, styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'

import { IPageLink, PageLinkType } from '../constants'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'

interface Props {
    accountLinks: IPageLink[],
    navigationLinks: IPageLink[],
    userDisplayName?: string,
    userEmail?: string,
    userDisplayImage: string,
    currentRoute: string,
    numberOfUnreadMessages: number
}

export default function Render(props: Props) {
    const { t } = useTranslation()
    const { colours } = useTheme()

    const [showDrawer, setShowDrawer] = useState<null | string>(null)

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: colours.red,
            color: colours.red,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""'
            }
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0
            }
        }
    }))
    
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', py: 1, width: '100%' }}>
                <Typography component='a' href='/dashboard' sx={{ flexGrow: 1 }}>
                    <Box
                        component='img'
                        src='/images/logo.svg' 
                        sx={{ width: '121px', height: '36px', display: 'block' }}
                    />
                </Typography>
                <Tooltip
                    data-tour-id="tour-header-account"
                    title={t('common.user_icon_hover_info.open_account_settings')} onClick={() => setShowDrawer('accountMenu')} sx={{ width: '50px', alignSelf: 'flex-end', flexGrow: 0 }}>
                    <IconButton onClick={() => setShowDrawer('accountMenu')} sx={{  pr: '20px' }} style={{ backgroundColor: 'transparent' }}>
                    <StyledBadge
                            overlap='circular'
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            variant='dot'
                            sx={{
                                '& .MuiBadge-badge': {
                                    display: props.numberOfUnreadMessages === 0 ? 'none' : 'block'
                                }
                            }}
                        >
                            <Avatar alt={props.userDisplayName} sx={{ width: 40, height: 40 }} 
                                src={props.userDisplayImage} />
                        </StyledBadge>
                    </IconButton>
                </Tooltip>           
                <IconButton
                    data-tour-id="tour-header-doggies-mobile"
                    size='large' onClick={() => setShowDrawer('navigationMenu')} color='primary' 
                    sx={{
                        width: '50px', 
                        alignSelf: 'center', 
                        flexGrow: 0 
                    }}>
                    <Box
                        component='img'
                        src='/images/icons/menu-open.svg' 
                        sx={{ width: '24px', height: '24px', fill: colours.greenDark }}
                    />
                </IconButton>
            </Box>
            <SwipeableDrawer
                anchor='left'
                open={showDrawer != null}
                onClose={() => setShowDrawer(null)}
                onOpen={() => setShowDrawer(null)}
                PaperProps={{ sx: { width: '85%' } }}
                disableSwipeToOpen
            >
                <Grid container sx={{ width: '100%', borderBottom: '1px solid', borderBottomColor: colours.greyLight }}>
                    <Grid xs sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                        <Box
                            component='img'
                            src='/images/logo.svg' 
                            sx={{ width: '121px', height: '36px', display: 'block' }}
                        />
                    </Grid>
                    <Grid xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', p: 2 }}>
                        <IconButton size='large' onClick={() => setShowDrawer(null)} color='primary'>
                            <Box
                                component='img'
                                src='/images/icons/close.svg' 
                                sx={{ width: '24px', height: '24px', fill: colours.greenDark }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
                    
                { showDrawer == 'navigationMenu' && ( 
                    props.navigationLinks.map((page) => { 
                        const isCurrentPage = props.currentRoute == page.link

                        return (
                            <MenuItem 
                                component={Link}
                                to={page.link}
                                key={page.text} 
                                onClick={() => setShowDrawer(null)}
                                sx={{ borderBottom: '1px solid', borderBottomColor: colours.greyLight, backgroundColor: isCurrentPage ? colours.greenLight : 'transparent',
                                '&:hover': {
                                    backgroundColor: colours.greenLight,
                                }
                            }}
                            >
                                <Typography textAlign='center' sx={{ fontSize: 16, color: colours.greyDark, py: 2, fontWeight: isCurrentPage ? 500 : 400 }}>{page.text}</Typography>
                            </MenuItem>
                        )
                    })
                )}
                { showDrawer == 'accountMenu' && ( 
                    <>
                        <Box
                            component={Link}
                            to={props.accountLinks[0].link}
                            onClick={() => {
                                setShowDrawer(null)
                            }}
                            sx={{ 
                                width: '100%', 
                                display: 'flex', 
                                gap: 2, 
                                p: 2, 
                                alignItems: 'center',
                                my: 2,
                                textDecoration: 'none'
                            }}
                        >
                            <Avatar alt={props.userDisplayName} sx={{ width: 60, height: 60 }} 
                                src={props.userDisplayImage} 
                            />
                            <Stack>
                                <Typography component={'span'} 
                                    sx={{
                                        color: colours.greyDark, size: 16, fontWeight: 500
                                    }}
                                >
                                    {props.userDisplayName}
                                </Typography>
                                <Typography 
                                    component={'span'}
                                    sx={{
                                        color: colours.grey, 
                                        fontSize: '14px', 
                                        fontWeight: 400
                                    }}
                                >
                                    {props.accountLinks[0].text}
                                </Typography>
                            </Stack>
                        </Box>
                        
                        { props.accountLinks.filter(x => x.alias !== 'my_profile').map((setting) => {
                            const isCurrentPage = props.currentRoute == setting.link

                            return ( 
                                <>
                                    {
                                        setting.type === PageLinkType.INTERNAL && (
                                            <MenuItem key={setting.text} onClick={() => setShowDrawer(null)} component={Link} to={setting.link} sx={{
                                                backgroundColor: isCurrentPage ? colours.greenLight : 'transparent',
                                                '&:hover': {
                                                    backgroundColor: colours.greenLight,
                                                } 
                                            }}>
                                                <Typography 
                                                    sx={{ textDecoration: 'none', color: colours.greenDark, fontWeight: 400, fontSize: 16, py: 2, width: '100%' }}
                                                    textAlign='center'>
                                                        <Grid container spacing={1}>
                                                            <Grid><Avatar sx={{ width: 24, height: 24 }} src={setting.iconUrl} /></Grid>
                                                            <Grid>
                                                                {setting.text}            
                                                            </Grid>
                                                            <Grid>
                                                                { 
                                                                    setting.alias === 'messages'
                                                                    && props.numberOfUnreadMessages > 0 
                                                                    && (
                                                                        <Avatar
                                                                            sx={{
                                                                                backgroundColor: colours.greenDark,
                                                                                color: colours.white,
                                                                                fontSize: '12px',
                                                                                mt: '2px',
                                                                                ml: 'auto',
                                                                                p: '4px 6px',
                                                                                width: 'auto',
                                                                                height: 'auto',
                                                                                lineHeight: '12px',
                                                                                textAlign: 'center'
                                                                            }}>
                                                                            {props.numberOfUnreadMessages}
                                                                        </Avatar>
                                                                    )
                                                                }   
                                                            </Grid>
                                                            <Grid ml='auto' mr='10px'>
                                                                { 
                                                                    setting.alias === 'messages'
                                                                    && props.numberOfUnreadMessages > 0 
                                                                    && (
                                                                        <StyledBadge
                                                                            sx={{
                                                                                ml: 'auto'
                                                                            }}
                                                                            overlap='circular'
                                                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                                            variant='dot'
                                                                        />
                                                                    )
                                                                }   
                                                            </Grid>
                                                        </Grid>           
                                                </Typography>
                                            </MenuItem>
                                        )
                                    }
                                    {
                                        setting.type === PageLinkType.EXTERNAL && (
                                            <MenuItem 
                                                key={setting.text} 
                                                onClick={() => {
                                                    setShowDrawer(null)
                                                    setting.onClick!()
                                                }}
                                                component='a'
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: colours.greenLight,
                                                    } 
                                                }}
                                            >
                                                <Typography 
                                                    sx={{ textDecoration: 'none', color: colours.greyDark, fontWeight: 400, fontSize: 16, py: 2, width: '100%' }}
                                                    textAlign='center'>
                                                        <Grid container spacing={1}>
                                                            <Grid><Avatar sx={{ width: 24, height: 24 }} src={setting.iconUrl} /></Grid>
                                                            <Grid>{setting.text}</Grid>
                                                        </Grid>
                                                </Typography>
                                            </MenuItem>
                                        )
                                    }
                                </>
                            )
                        })}
                    </>
                )}
            </SwipeableDrawer>
        </>
    )
}