import { LOG_LEVEL } from './environment'

/** Signature of a logging function */
export interface LogFn {
  (message?: any, ...optionalParams: any[]): void
}

/** Basic logger interface */
export interface Logger {
  information: LogFn
  warning: LogFn
  error: LogFn
}

/** Log levels */
export type LogLevel = 'information' | 'warning' | 'error'

//const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {} // comment out due to compiler warnings - replaced with below
const NO_OP: LogFn = () => {}

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
  readonly information: LogFn
  readonly warning: LogFn
  readonly error: LogFn

  constructor(options?: { level? : LogLevel }) {
    const { level } = options || {}

    this.error = console.error.bind(console)

    if (level === 'error') {
      this.warning = NO_OP
      this.information = NO_OP

      return
    }
    
    this.warning = console.warn.bind(console)

    if (level === 'warning') {
      this.information = NO_OP

      return
    }

    this.information = console.log.bind(console)
  }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL })