import React, { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import MenuItem from "@mui/material/MenuItem";

import { availableLanguages } from "../../../../../i18n";
import { IPageLink } from "../constants";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { Stack } from "@mui/material";
import Divider from "@mui/material/Divider/Divider";
import {useAuth0} from "@auth0/auth0-react";


interface Props {
	accountLinks: IPageLink[];
	navigationLinks: IPageLink[];
	userDisplayName?: string;
	currentRoute: string;
}

export default function Render(props: Props) {

	const { loginWithRedirect } = useAuth0()
	const { t, i18n } = useTranslation()
    const { colours } = useTheme()
	const navigate = useNavigate()

	const language = useMemo(() => {
		return i18n.language === 'dog' ? i18n.language : i18n.resolvedLanguage
	}, [i18n.language, i18n.resolvedLanguage])

    const handleLanguageChanged = useCallback((language: string) => {
        i18n.changeLanguage(language)
        
        navigate(window.location.pathname
            .replace('/fr', `/${language}`)
            .replace('/en', `/${language}`)
            .replace('/de', `/${language}`))
	}, [i18n, navigate]);

	return (
		<Grid container sx={{ width: "100%" }}>
			<Grid xs={1}>
				<Typography component={Link} to={`/${i18n.resolvedLanguage}`}>
					<Box component="img" src="/images/logo.svg" sx={{ width: "154px", height: "48px", display: "block" }} alt="Home" width="154" height="48" />
				</Typography>
			</Grid>
			<Grid xs sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<Box sx={{ display: "flex", gap: "30px" }}>
					{props.navigationLinks.map((page) => {
						const isCurrentPage = props.currentRoute === page.link;

						return (
							<Typography
								component={Link}
								to={`/${i18n.resolvedLanguage}/${page.link}`}
								key={page.text}
								sx={{
									display: "block",
									fontSize: 16,
									textDecoration: "none",
									fontWeight: 500,
									lineHeight: "26px",
									color: colours.greyDark,
									p: 1,
									borderTop: "2px solid transparent",
									borderBottom: "2px solid transparent",
									borderBottomColor: isCurrentPage ? colours.greenDark : "transparent",
									"&:hover": {
										borderBottomColor: colours.greenDark,
									},
								}}
							>
								{t(page.translationKey)}
							</Typography>
						);
					})}
				</Box>
			</Grid>
			<Grid lg="auto" sx={{ display: "flex", alignItems: "right" }}>
				<Stack
					direction={'row'}
					divider={
						<Divider
							orientation='vertical'
							flexItem
						/>
					}
					gap={4}
					sx={{ color: colours.greyDark }}
				>
					<IconButton
						onClick={() => loginWithRedirect()}
						sx={{ p: 0 }}
						style={{ backgroundColor: 'transparent' }}
						data-tour-id='tour-header-account'
					>
						<Avatar
							sx={{ width: 35, height: 35 }}
							src={'/images/placeholder-avatar.svg'} 
						/>

						<Typography noWrap component={'span'} sx={{ ml: 2, display: 'inline', color: colours.greyDark, size: 16, fontWeight: 500, maxWidth: '250px' }}>
							{t('button.sign_in')}
						</Typography>
					</IconButton>
					
					<Select
						sx={{ 
							boxShadow: 'none', 
							'.MuiOutlinedInput-notchedOutline': { border: 0 },
							pl: 0
						}}
						startAdornment={<Box
							component='img'
							src='/images/icons/language.svg'
							sx={{ width: '35px', height: '35x', display: 'block', mr: '10px' }}
							alt=""
							width="24" height="24"
						/>}
						variant="outlined"
						value={language}
						onChange={(e) => handleLanguageChanged(e.target.value)}
					>
						{availableLanguages.map((availableLanguage) => (
							<MenuItem key={availableLanguage} value={availableLanguage}>{availableLanguage.split("-")[0].toUpperCase()}</MenuItem>
						))}
					</Select>
				</Stack>
			</Grid>
		</Grid>
	);
}
