import * as Types from './types/types.t.d';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type tryCreateUserMutationVariables = Types.Exact<{
  input: Types.TryCreateUserInput;
}>;


export type tryCreateUserMutation = { __typename?: 'Mutation', tryCreateUser: { __typename?: 'TryCreateUserPayload', user?: { __typename?: 'User', id: any, displayName: string, locale?: string | null, imageProfile?: { __typename?: 'MediaImage', id: any, url?: string | null, urlThumbnail?: string | null } | null } | null } };


export const tryCreateUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"tryCreateUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TryCreateUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tryCreateUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"locale"}},{"kind":"Field","name":{"kind":"Name","value":"imageProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"urlThumbnail"}}]}}]}}]}}]}}]} as unknown as DocumentNode<tryCreateUserMutation, tryCreateUserMutationVariables>;