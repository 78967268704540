import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AppProviderGraphQL from './app-provider-graphql'

declare module '@mui/material/styles' {
    interface Theme {
        colours: {
            white: string,
            red: string,
            greenLight: string,
            greenDark: string,
            greyLightExtra: string,
            greyLight: string,
            grey: string,
            greyDark: string
        }
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        colours: {
            white: string,
            red: string,
            greenLight: string,
            greenDark: string,
            greyLightExtra: string,
            greyLight: string,
            grey: string,
            greyDark: string
        }
    }
}

export default function AppProviderTheme() {
    
    // https://mui.com/material-ui/customization/theme-components/
    const theme = createTheme({
        palette: {
            primary: {
                main: '#324B40',
            },
            secondary: {
                main: '#E9F3F0',
            },
        },
        typography: {
            fontFamily: 'Poppins, sans-serif'
        },
        colours: {
            white: '#FFFFFF',
            red: '#FF0000',
            greenLight: '#E9F3F0',
            greenDark: '#324B40',
            greyLightExtra: '#F7F7F7',
            greyLight: '#D9D9D9',
            grey: '#747474',
            greyDark: '#2A2925'
        }
    })
    
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppProviderGraphQL />
        </ThemeProvider>
    )
}