import React, {useEffect, useMemo, useState} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector } from 'react-redux'
import {useLocation, useParams} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { RootState } from '../../../store'

import { IPageLink, PageLinkType } from './constants'
import HeaderMobile from './components/header-mobile'
import HeaderDesktop from './components/header-desktop'
import { useShowTranslationKeys } from '../../../hooks'

import { OnMessageSummaryReceivedDocument, messageSummariesByUserIdDocument } from './graphql'
import { logger } from '../../../../logger'
import {userBySignedInIdWithSubscriptionDocument} from "../../account-page/components/subscription/graphql";
import useScrollDirection from "../header-public/components/use-scroll-direction";

export default function Render() {  

    useShowTranslationKeys()
    const location = useLocation()
    const scrollDirection = useScrollDirection()
    const { breakpoints, colours } = useTheme()
    const { i18n, t } = useTranslation()
    const { logout } = useAuth0()

    const navigationLinks: IPageLink[] = [
        { text: `${t('header.nav.dashboard')}`, link: '/dashboard', type: PageLinkType.INTERNAL } as IPageLink,
        { text: 'Doggies', link: '/doggy/search', type: PageLinkType.INTERNAL, dataTourId: 'tour-header-doggies' } as IPageLink,
        { text: 'Buddies', link: '/buddy/search', type: PageLinkType.INTERNAL, dataTourId: 'tour-header-buddies' } as IPageLink
    ]

    const displayName = useSelector((state: RootState) => state.userState.displayName)
    const displayImage = useSelector((state: RootState) => state.userState.displayImage)
    const locale = useSelector((state: RootState) => state.userState.locale)

    const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState<number>(0)

    const { data: userBySignedInUserIdData } = useQuery(userBySignedInIdWithSubscriptionDocument)
    const { data: messageSummariesData, subscribeToMore: messageSummariesSubscribeToMore } = useQuery(messageSummariesByUserIdDocument)

    const isMobileMenu = useMediaQuery(() => breakpoints.down('md'))

    const accountLinks: IPageLink[] = [
        { alias: 'my_profile', text: t('user_menu.option.my_profile'), link: `/user/profile/${userBySignedInUserIdData?.userBySignedInId?.id}`, type: PageLinkType.INTERNAL, iconUrl: displayImage } as IPageLink,
        { alias: 'account', text: t('user_menu.option.account'), link: '/account', type: PageLinkType.INTERNAL, iconUrl: '/images/icons/account.svg' } as IPageLink,
        { alias: 'messages', text: t('user_menu.option.messages'), link: '/messages', type: PageLinkType.INTERNAL, iconUrl: '/images/icons/messages.svg' } as IPageLink,
        { alias: 'logout', text: t('user_menu.option.logout'), link: '/logout', type: PageLinkType.EXTERNAL, iconUrl: '/images/icons/logout.svg',
         onClick: (() => { 
            logout({
                logoutParams: {
                    returnTo: `${process.env['REACT_APP_SITE_URL']}`
                } 
            })
         }),
        } as IPageLink
    ]

    const { userId } = useMemo(() =>{
        return { userId: userBySignedInUserIdData?.userBySignedInId?.id }
    }, [userBySignedInUserIdData])

    useEffect(() => {
        i18n.changeLanguage(locale)
     }, [i18n, locale])
     
    useEffect(() => {
        logger.information('[header]: useEffect: subscribeToMoreMessageSummaries')
        
        if (!userId)
            return

        messageSummariesSubscribeToMore({
            document: OnMessageSummaryReceivedDocument,
            variables: { userId: userId },
            updateQuery: (prev, { subscriptionData }) => {
                logger.information('[header]: useEffect: subscribeToMoreMessageSummaries: updateQuery')

                if (!subscriptionData.data) 
                    return prev

                const existingMessageSummary =
                    prev
                    .messageSummariesByUserId
                    .find(x => x.recipientUserId == subscriptionData.data.messageSummaryReceived?.recipientUserId)

                if (existingMessageSummary)
                {
                    logger.information('[header]: useEffect: subscribeToMoreMessageSummaries: updateQuery: updating existing message summary')

                    return Object.assign(
                        {}, 
                        prev, 
                        { 
                            messageSummariesByUserId: [
                                ...prev.messageSummariesByUserId.map(messageSummary => 
                                    messageSummary.recipientUserId == existingMessageSummary.recipientUserId 
                                    ? Object.assign({}, existingMessageSummary, subscriptionData.data.messageSummaryReceived) 
                                    : messageSummary
                                )
                            ] 
                        }
                    )                    
                }
                else
                {
                    logger.information('[header]: useEffect: subscribeToMoreMessageSummaries: updateQuery: appending new message summary')

                    return Object.assign(
                        {}, 
                        prev, 
                        { 
                            messageSummariesByUserId: [
                                ...prev.messageSummariesByUserId, 
                                subscriptionData.data.messageSummaryReceived
                            ] 
                        }
                    )                    
                }
            }
        })
    }, [messageSummariesSubscribeToMore, userId])

    useEffect(() =>{
        if (!messageSummariesData)
            return
        
        logger.information('[header]: useEffect: update number of unread messages')
        
        const totalNumberOfAllUnreadMessages = messageSummariesData
            ?.messageSummariesByUserId
            .reduce(
                (total, currentItem) => (total = total + currentItem.numberOfUnreadMessages),
                0,
            ) ?? 0

        if (numberOfUnreadMessages !== totalNumberOfAllUnreadMessages){
            setNumberOfUnreadMessages(totalNumberOfAllUnreadMessages)
        }
    }, [messageSummariesData, numberOfUnreadMessages])

    return (
        <AppBar
            sx={{
                position: "sticky",
                backgroundColor: "#fff",
                boxShadow: "none",
                borderBottom: "1px solid",
                borderColor: colours.greyLight,
                boxSizing: "border-box",
                zIndex: '10',
                top: scrollDirection === "down" ? '-6em' : 0,
                transitionProperty: 'all',transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',transitionDuration: '500ms'
            }}
        >
            <Container maxWidth='xl'>
                <Toolbar 
                    disableGutters
                    sx={{
                        minHeight: { xs: 'unset', md: 96 },
                        [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
                            minHeight: { xs: 'unset', md: 96 }
                        },
                        [breakpoints.up('sm')]: {
                            minHeight: { xs: 'unset', md: 96 }
                        }
                    }}
                >
                    { 
                        isMobileMenu 
                            ? <HeaderMobile 
                                accountLinks={accountLinks} 
                                navigationLinks={navigationLinks} 
                                userDisplayName={displayName} 
                                userDisplayImage={displayImage}
                                currentRoute={location.pathname}
                                numberOfUnreadMessages={numberOfUnreadMessages} /> 
                            : <HeaderDesktop 
                                accountLinks={accountLinks}
                                navigationLinks={navigationLinks} 
                                userDisplayName={displayName} 
                                userDisplayImage={displayImage}
                                currentRoute={location.pathname}
                                numberOfUnreadMessages={numberOfUnreadMessages} /> 
                    }
                </Toolbar>
            </Container>
        </AppBar>
    )
}